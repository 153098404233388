<template>
  <div class="background-img-3 flx flx-d-column fx-center flx-d-column">
    <div class="fx-center flx-d-column">
      <img src="../assets/img/icon-2-white.png" alt="editon" />
      <h2 class="title-h2">CADASTRAR</h2>
    </div>
    <div class="fx-center flx-d-column">
      <input
        class="input-style"
        placeholder="NOME"
        type="text"
        name="name"
        id="name"
        v-model="form.fullName"
      />
      <input
        class="input-style"
        placeholder="E-MAIL"
        type="text"
        name="email"
        id="email"
        v-model="form.email"
      />
      <input
        class="input-style"
        placeholder="SENHA"
        type="password"
        name="password"
        id="password"
        v-model="form.password"
      />
      <button @click="sendRegister" class="btn-signing" type="button">Cadastrar</button>
    </div>
  </div>
</template>

<style>
</style>

<script>
import router from "../router";
import axios from "axios";
import { apiBaseUrl } from "../services/api";
import headerBuilder from "../services/headerBuilder";
import LoaderMixin from "../mixins/LoaderMixin";

export default {
  name: "Register",
  mixins: [LoaderMixin()],
  data: function () {
    return {
      form: {
        fullName: "",
        email: "",
        password: "",
      },
    };
  },
  methods: {
    async sendRegister() {
      await this.loaderify(() =>
        axios.post(
          `${apiBaseUrl}/api/Register/RegisterVideoEditorUser`,
          this.form,
          { headers: headerBuilder.build() }
        )
      );
      router.push({
        name: "Login",
        params: {
          auto_login_form: this.form,
        },
      });
    },
  },
};
</script>
